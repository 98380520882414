.loader-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #ccc;
  border-top: 4px solid #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#global-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: opacity 0.3s ease;
}

#global-loader.hidden {
  opacity: 0;
  pointer-events: none;
}

.dynamicLoading {
  position: relative;

  .component-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    transition: opacity 0.3s ease;
  }

  &.hiddenComponent {
    > *:not(.component-loader) {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    .component-loader {
      opacity: 1;
      pointer-events: all;
    }
  }

  &.visibleComponent {
    > *:not(.component-loader) {
      opacity: 1;
    }
    .component-loader {
      opacity: 0;
      pointer-events: none;
    }
  }
}
